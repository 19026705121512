@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,100;1,300;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,700;1,700&display=swap);
:root {
  --white: white;
  --dirty-white:#f0f0f0;
  --text: #403c4a;
  --text-emphasized: #201d29;
  --text-muted: #78757f;
  --text-pink: #c10b7e;
  --yellow: #ffe742;
  --yellow-dark: #ddc62c;
  --pink: #ff87d4;
  --pink-faded: #f5f2fc;
  --pink-dark: #c10b7e;
  --blue: #87fffb;
  --blue-dark: #63ddd9;
  --black: #171321;
  --gray-light: #d4d0e0;
  --gray-medium: #b1acb9;
  --font-family: jwf, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  --background: var(--white);
  --foreground: var(--black);
  --footnote-hover: var(--pink-dark);
}

* {
  font-family: var(--font-family);
  overscroll-behavior: auto;
  padding: 0px;
  margin: 0px;
}
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 1.45;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

.outer {
  color: var(--text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--foreground);
  font-weight: 900;
  line-height: 1.1;
  transition: 200ms color linear;
}

a {
  color: var(--text-pink);
  font-weight: 400;
}

strong,
b {
  font-weight: 600;
}

img {
  max-width: 100%;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}
.cools {
  mix-blend-mode: overlay;
  z-index: 10;
}

.attractor {
  z-index: 10;
}

/* ------------------------ */


.site-header {
    background: var(--black);
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 1rem 2rem;
    position: relative;
    top: 0;
    z-index: 1000;
  }
  
  .site-header::after {
    background-image: linear-gradient(
      to right,
      var(--pink) 0%,
      var(--yellow-dark) 50%,
      var(--blue) 100%
    );
    content: '';
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
  }
  
  .home {
    color: var(--white);
    font-weight: 400;
    position: -webkit-sticky;
    position: sticky;
    text-decoration: none;
  }
  
  .site-nav a {
    color: var(--white);
    text-decoration: none;
  }
  
.footer {
    background: var(--background);
    color: var(--text-muted);
    display: flex;
    font-size: 0.875rem;
    justify-content: space-between;
    padding: 2rem;
    text-align: center;
  }
  
  .footer-nav {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: space-between;
  }
  
  .footer-nav a {
    color: inherit;
    text-decoration: none;
  }
  
  .footer-nav a:focus,
  .footer-nav a:hover {
    text-decoration: underline;
  }
  
 .block {
    background: var(--background);
    border-bottom: 1px solid var(--gray-light);
    padding: 5rem 5vw;
    transition: 200ms background linear;
    position: relative;
  }

  .block.yellow {
    background: var(--yellow);
    border-color: var(--yellow-dark);
  }

  .block.dirty-white {
    background: var(--dirty-white);
    border-color: var(--white);
  }
.hero {
  height: 70vh;
  margin: 0 auto;
  max-height: 100vh;
  max-width: 700px;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 90vw;
  z-index: 1;
}

.hero-first-line {
  display: block;
  font-size: clamp(20px, 25.5vw, 120px);
  line-height: 0.95;
  color: var(--black);
  font-weight: 900;
}

.hero-first-line.maximum-boops {
  font-size: clamp(20px, 26.5vw, 120px);
}

.hero-box {
  background: var(--black);
  color: var(--background);
  display: block;
  margin: auto;
  text-align: center;
  font-size: clamp(20px, 10.5vw, 120px);
  font-weight: 900;
  line-height: 1;
  padding: 0 0.5rem;
  transition: 200ms background linear;
}

.hero-tagline {
  display: block;
  font-size: var(--size, 2.42vw);
  margin-top: 0.125rem;
  font-weight: 900;
}

.love {
  background: url('https://res.cloudinary.com/jlengstorf/image/upload/q_auto,f_auto/v1593755650/jason.af/love.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;
  display: inline-block;
  height: 1.2em;
  margin: -0.1em -3px;
  position: relative;
  top: var(--top, -0.05em);
  transform: scale(var(--scale, 1.1)) rotate(var(--rotation, -11deg)) translateY(0.1em);
  transform-origin: center;
  width: 1.6em;
}

.hero-cycle {
  background: transparent;
  border: none;
  color: var(--text-muted);
  display: block;
  font-size: 0.75rem;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  font-weight: 400;
  letter-spacing: 0.2em;
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 30px;
  z-index: 20;
}

.hero-cycle.cycle-is-active {
  animation-duration: 500ms;
  animation-name: spin-hero-cycle;
  animation-iteration-count: 1;
}

.hero-cycle img {
  width: 100%;
}

@keyframes spin-hero-cycle {
  from {
    transform: rotate(-1turn);
  }

  to {
    transform: rotate(0);
  }
}

.temp-tagline {
  display: block;
  font-size: 2.42vw;
  margin-top: 0.125rem;
  color: var(--black);
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.gallery-image {
margin: 3rem auto;
max-width: 90vw;
padding: 0;
width: 400px;
}

.gallery-image > div {
height: 0 !important;
max-width: 100% !important;
max-width: 100% !important;
padding-bottom: 100% !important;
}

.gallery-image img {
width: 100%;
}

.gallery-image figcaption {
color: var(--text-muted);
margin: 1rem 0 0;
}

.gallery-links {
display: flex;
font-size: 0.625rem;
font-weight: 400;
justify-content: space-between;
letter-spacing: 0.15em;
margin-top: 0.5rem;
text-transform: uppercase;
}

.gallery-credit {
display: block;
}

.gallyer-fullsize-link,
.gallery-credit a {
text-decoration: none;
}

.gallery-options {
margin-bottom: 4rem;
}

.gallery-heading {
color: var(--text-muted);
font-size: 0.625rem;
font-weight: 400;
letter-spacing: 0.2em;
margin-bottom: 1rem;
margin-top: 0;
text-align: center;
text-transform: uppercase;
}

.gallery-thumbnails {
display: grid;
grid-gap: 20px;
gap: 20px;
grid-template-columns: repeat(auto-fit, 50px);
justify-content: center;
list-style: none;
margin: 0;
padding: 0;
}

.gallery-thumb-link {
background: none;
border: none;
display: block;
height: 50px;
}

.gallery-thumb-link:focus,
.gallery-thumb-link:hover {
outline: 2px solid var(--text-pink);
outline-offset: 0.25rem;
}

.gallery-thumb {
opacity: 1;
transition: 200ms opacity linear;
}

.gallery-thumb.active {
opacity: 0.5;
}

@media (min-width: 900px) {
.gallery-image {
    margin-top: 0;
}

.gallery-options {
    margin-bottom: 0;
}
}
.bio {
  font-size: 1.25rem;
}

.bio-container {
  margin: 0 auto;
  max-width: 1100px;
  width: 90vw;
}

.bio-heading {
  font-size: 1.25rem;
  margin: 0;
  font-size: larger;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.bio-profiles {
  align-content: center;
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(4, 60px);
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.bio-image-container {
  margin: 0 0 4rem;
  padding: 0;
  /* height: 300px; */
}

@media (min-width: 900px) {
  .bio-heading {
    font-size: 1.75rem;
  }

  .bio-container {
    display: grid;
    grid-gap: 6rem;
    gap: 6rem;
    grid-template-columns: 1fr 400px;
  }

  .bio-image-container {
    margin-bottom: 0;
  }
}

.bio-length-control {
  margin: 1rem auto 3rem;
  max-width: 90vw;
  width: 300px;
}

.bio-fieldset {
  border: none;
  padding: 0;
}

.bio-legend {
  color: var(--text-muted);
  font-size: 0.75rem;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  font-weight: 400;
  letter-spacing: 0.15em;
  margin: 0;
  padding: 0;
  text-transform: lowercase;
}

.bio-length-options {
  align-items: center;
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(6, 1fr);
}

.bio-option {
  cursor: pointer;
  height: calc((300px - 10rem) / 6 + 1rem);
  position: relative;
}

.bio-input {
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.bio-label-text {
  clip: rect(1px, 1px, 1px, 1px);
  color: var(--text-muted);
  font-weight: 400;
  height: 1px;
  letter-spacing: 0.2em;
  overflow: hidden;
  position: absolute;
  top: 80%;
  white-space: nowrap;
  width: 1px;
}

.bio-option:first-of-type .bio-label-text,
.bio-option:last-of-type .bio-label-text {
  clip: auto;
  height: auto;
  overflow: visible;
  width: 60px;
}

.bio-option:first-of-type .bio-label-text {
  left: 0;
  text-align: left;
}

.bio-option:last-of-type .bio-label-text {
  right: 0;
  text-align: right;
}

.bio-label {
  cursor: pointer;
  font-size: 0.75rem;
}

.bio-label::before,
.bio-label::after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 0;
  left: 50%;
  padding-bottom: calc(1rem - 2px);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
}

.bio-label::before {
  border: 1px solid var(--pink);
  transition: 200ms background linear;
  z-index: 10;
}

.bio-label::after {
  background: var(--pink);
  opacity: 0;
  transition: 200ms opacity linear;
}

:checked + .bio-label::before,
:hover + .bio-label::before,
:focus + .bio-label::before {
  border-color: var(--text-pink);
}

:focus + .bio-label::before {
  outline: 2px solid var(--pink);
  outline-offset: 0.125rem;
}

:checked + .bio-label::after {
  opacity: 1;
}

.bio-social {
  margin-top: 3rem;
}

.bio-connect {
  color: var(--text-muted);
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.2em;
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
}

.bio-link {
  background: var(--text-pink);
  border-radius: 50%;
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  padding: 1.125rem;
  transition: all 200ms linear;
  transition-delay: 150ms;
}

.bio-link:focus,
.bio-link:hover {
  background: var(--text-pink);
  outline: none;
  transition-delay: 0ms;
}

.bio-link path {
  fill: var(--white);
  transition: 200ms fill linear;
  transition-delay: 150ms;
}

.bio-link:focus path,
.bio-link:hover path {
  fill: var(--blue);
  transition-delay: 0ms;
}

.writing {
    margin: 0 auto;
    max-width: 1150px;
  }
  
  .writing-button {
    background: var(--pink);
    border: 2px solid transparent;
    border-radius: 7px;
    color: var(--black);
    display: block;
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-weight: 900;
    padding: 0.25rem 1rem;
    margin: 3rem auto 0;
    max-width: 220px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 200ms background linear;
  }
  
  .writing-button:focus,
  .writing-button:hover {
    background: var(--text-pink);
    border-color: var(--foreground);
    outline: none;
  }
  
.post-preview {
    margin: 3rem auto 0;
    max-width: 250px;
    position: relative;
    background-color: white;
    padding: 1rem;
  }
  
  .post-preview-site {
    color: var(--text-muted);
    font-size: 0.75rem;
    font-feature-settings: "smcp";
    font-variant: small-caps;
    font-weight: 400;
    letter-spacing: 0.2em;
    margin: 0 0 1rem;
    text-transform: lowercase;
  }
  
  .post-preview-site img {
    margin-right: 0.5rem;
    width: 16px;
    vertical-align: middle;
  }
  
  .post-preview-image {
    width: 100%;
  }
  
  .post-preview-title {
    margin: 0.5rem 0;
  }
  
  .post-preview-title-link {
    color: var(--foreground);
    text-decoration: none;
  }
  
  .post-preview-title-link:focus {
    outline: none;
  }
  
  .post-preview-title-link::after {
    background: transparent;
    bottom: 0;
    content: '';
    cursor: pointer;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  
  .post-preview-title-link:focus::after,
  .post-preview-title-link:hover::after {
    outline: 2px solid var(--text-pink);
    outline-offset: 0.5rem;
  }
  
  .post-preview-description {
    margin: 0.5rem 0 0;
    position: relative;
    z-index: 20;
  }
  
  .post-preview-link {
    color: var(--text-pink);
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.2em;
    margin: 0.5rem 0 0;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  @media (min-width: 500px) {
    .post-previews {
      display: grid;
      grid-gap: 3rem;
      gap: 3rem;
      grid-template-columns: repeat(auto-fit, 250px);
      justify-content: center;
      margin: 0 auto;
      max-width: 1150px;
      grid-row-gap: 4rem;
      row-gap: 4rem;
    }
  
    .post-preview {
      margin: 0;
    }
  }
  
  @media (min-width: 500px) {
    .post-previews {
      display: grid;
      grid-gap: 3rem;
      gap: 3rem;
      grid-template-columns: repeat(auto-fit, 250px);
      grid-row-gap: 4rem;
      row-gap: 4rem;
      justify-content: center;
    }
  
    .post-preview {
      margin: 0;
    }
  }
  
.intro {
    margin: 0 auto 3rem;
    max-width: 90vw;
    width: 900px;
  }
  
  .intro:only-child {
    margin-bottom: 0;
  }
  
  .intro-headline {
    font-size: 11vw;
    margin: 0;
    text-align: center;
  }
  
  .intro-lede {
    font-size: 1.25rem;
    margin: 1rem 0 0;
    text-align: center;
  }
  
  .intro-lede p {
    margin: 1rem 0 0;
  }
  
  @media (min-width: 600px) {
    .intro-headline {
      font-size: 7vw;
    }
  }
  
  @media (min-width: 950px) {
    .intro-headline {
      font-size: 4.125rem;
    }
  }
  
.writing {
    margin: 0 auto;
    max-width: 1150px;
  }
  
  .writing-button {
    background: var(--pink);
    border: 2px solid transparent;
    border-radius: 7px;
    color: var(--black);
    display: block;
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-weight: 900;
    padding: 0.25rem 1rem;
    margin: 3rem auto 0;
    max-width: 220px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 200ms background linear;
  }
  
  .writing-button:focus,
  .writing-button:hover {
    background: var(--text-pink);
    border-color: var(--foreground);
    outline: none;
  }
  
